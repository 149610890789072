export const ENDPOINTS_CONFIG = {
  api: {
    login: "/users/login/",
    register: "/users/register/",
    users: "/users/list/",
    profile: "/users/profile/",
    portfolio: "/users/portfolio/",
    userProfile: "/users/",
    skills: "/users/skills_change/",
    recover: "/users/password_reset/",
    reset: "/users/password_reset_confirm/",
    changePassword: "/users/change_password/",
    activateAccount: "/users/activate/",
    userList: "/users/listfull/",
    blackList: "/users/blacklist/",
    addToBlackList: "/users/blacklist/add/",
    removeOfBlackList: "/users/blacklist/remove/",
  },
};
